@import "../../../themes/generated/variables.base.scss";

.mxp-notificacoes-badge {
  position: absolute;
  background-color: rgb(217, 83, 79);
  border-radius: 50%;
  text-align: center;
  aspect-ratio: 1/1;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  font-weight: 800;
  translate: -12px -2px;
  user-select: none;
  cursor: pointer;
}

.mxp-notificacoes-badge:hover {
  filter: brightness(110%);
}

.mxp-notificacoes-nao-lida {
  background-color: rgba($base-accent, alpha($base-accent) * 0.3);
}

.mxp-lista-notificacao {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  place-items: center;
}

.mxp-texto-notificacao {
  padding: 0px;
  line-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notificacoes-popover {
  display: block !important;
}
.notificacoes-popover::after {
  background: #fff;
}

.animate-gelatine {
  animation: gelatine 0.5s;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
