/* Faz com que o botão de pesquisa fique mais próximo do fim do input.*/
.dx-texteditor-container
  .dx-texteditor-buttons-container:last-child
  > .mxp-botao-pesquisa.dx-button:last-child {
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  height: 100%;
  border: none;
  border-radius: 0px 4px 4px 0px;
  box-shadow: -1px 0 1px rgba(0, 0, 0, 0.2);
}

/* Muda cor de background dos inputs desabilitados. */
.dx-editor-outlined.dx-state-disabled.dx-widget,
.dx-editor-outlined.dx-state-readonly.dx-widget {
  background-color: #f4f4f4;
  cursor: default;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}

/* Muda cor do texto dos inputs desabilitados. */
.dx-editor-outlined.dx-state-disabled.dx-widget .dx-texteditor-container input,
.dx-editor-outlined.dx-state-readonly.dx-widget input {
  color: #9b9b9b;
  cursor: default;
}

.dx-editor-outlined.dx-state-disabled.dx-widget,
.dx-editor-outlined.dx-state-disabled.dx-widget .dx-texteditor-container input {
  pointer-events: visible;
  cursor: not-allowed;
}

/* Altera opacidade dos inputs desabilitados (Por padrão é 0.38). */
.dx-editor-outlined.dx-state-disabled.dx-widget {
  opacity: 0.5;
}

/* Muda cursor dos botões dentro dos inputs desabilitados. */
.dx-editor-outlined.dx-state-readonly.dx-widget .dx-button,
.dx-editor-outlined.dx-state-disabled.dx-widget .dx-button {
  cursor: not-allowed;
}

.dx-texteditor .dx-dropdowneditor-button.mxp-dropdowneditor-button {
  pointer-events: none;
  margin-right: 2px;
}
