@import "../../../themes/generated/variables.base.scss";

.dx-datagrid-rowsview .coluna-de-espaco {
  display: none;
}

.coluna-de-espaco {
  border: 0 none $base-bg !important;
  background: $base-bg !important;
  // Corrige bug visual quando se há header agrupados, deixa a última coluna sem borda.
  border-left: 1px solid $base-border-color !important;
}

.dx-dropdownbutton
  .dx-widget.dx-button-mode-contained.dx-button-has-text
  .dx-button-content {
  background-color: #5cb85c;
  color: #fff;
}

.dx-dropdownbutton
  .dx-widget.dx-button-mode-contained.dx-button-has-text
  .dx-button-content
  .dx-icon-add,
.dx-dropdownbutton
  .dx-widget.dx-button-mode-contained.dx-button-has-text
  .dx-button-content
  .dx-icon-spindown {
  color: #fff;
}

.mxp-grid-compacto
  .dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-table
  .dx-header-row
  > td {
  padding: 8px 8px;
}

.mxp-grid-compacto
  .dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-filter-row
  .dx-editor-cell
  .dx-filter-range-content {
  padding-top: 2px;
  padding-bottom: 2px;
}

.mxp-grid-compacto
  .dx-datagrid
  .dx-datagrid-filter-row
  .dx-editor-cell
  .dx-texteditor
  .dx-texteditor-input {
  height: 28px;
  font-size: 12px;
}

.mxp-grid-compacto
  .dx-datagrid
  .dx-datagrid-rowsview
  .dx-datagrid-content
  .dx-row
  > td {
  padding: 6px 11px;
  font-size: 12px;
}

.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-row-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover.dx-row-lines
  > td {
  border-bottom: none;
}

@keyframes icone-salvar-animation {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(3px);
  }
  50% {
    transform: translateY(-3px);
  }
  75% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.mxp-toolbar-ic-enabled {
  animation: icone-salvar-animation 0.75s;
  color: $base-accent;
}

.dx-toolbar .dx-button.mxp-toolbar-ic-enabled .dx-icon {
  color: $base-accent;
}
