.menu-nav {
  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
}

// Configs para diminuir o tamanho do menu
.dx-swatch-menu-superior
  .dx-overlay-wrapper
  .dx-context-menu
  .dx-menu-item
  .dx-menu-item-content {
  padding: 4px;
}

.dx-swatch-menu-superior
  .dx-overlay-wrapper
  .dx-context-menu
  .dx-menu-separator {
  margin: 2px 0;
}

.dx-swatch-menu-superior .dx-submenu .dx-scrollable-wrapper {
  max-height: inherit;
}

.dx-swatch-menu-superior
  .dx-submenu
  .dx-scrollable-wrapper
  .dx-scrollable-container {
  max-height: inherit;
}

.layout-header {
  z-index: 1501;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

// Possível solução temporária enquanto não é definido como será o equivalente ao botão de "novo!" para opções nos menus
.dx-icon.dx-icon-tags {
  color: #8bc34a;
}
