/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-85013 */

/* Mantém os botões sem Upercase */
.dx-button-text,
.dx-tab-content,
.dx-tab-text {
  text-transform: none;
}

/* Corrige a aparência do menu quando utilizar de links */
.dx-menu-item-content .dx-item-url {
  text-decoration: none !important;
  width: 100%;
  display: block;
}

/* Esconde o wrapper do scroll quando a grid está vazia */
.dx-datagrid .dx-datagrid-rowsview.dx-empty .dx-scrollable-wrapper {
  display: none;
}

/* Esconde o container wrapper do scroll quando a grid está vazia */
.dx-datagrid
  .dx-datagrid-rowsview.dx-empty
  > .dx-datagrid-content.dx-datagrid-content-fixed {
  display: none;
}

/* Ajusta fonte, posição e dimensionamento do elemento quando a grid estiver sem dados */
.dx-datagrid .dx-datagrid-rowsview.dx-empty .dx-datagrid-nodata {
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  top: 0%;
  left: 0%;
  width: auto;
  transform: translate(0%, 0%);
  padding: 10px;
}

/* Recalcula a largura para o conteudo do elemtento da grid */
.dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-action
  .dx-datagrid-text-content {
  width: calc(100% - 30px);
}
