/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84933 */

@import "../../../themes/generated/variables.base.scss";
$side-panel-min-width: 60px;

/* Ajustar posicionamento, z-index e cor do ícone do menu principal */
.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

/* Ajustar altura dos containers dos itens do menu */
.dx-swatch-menu-superior
  .header-component
  .dx-toolbar
  .dx-toolbar-items-container {
  height: var(--header-menu-height);
}

/* Ajustar margens dos ícones */
.dx-swatch-menu-superior
  .dx-toolbar-button
  .dx-menu
  .dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon
  .dx-menu-item-content
  .dx-icon {
  margin-right: 2px;
  margin-left: 0;
}

/* Ajustar tamanho do fonte dos ícones */
.dx-swatch-menu-superior .dx-menu-base .dx-menu-item-content .dx-icon {
  font-size: 16px;
}

/* Ajustar brilho dos ícones */
.dx-swatch-menu-superior img.dx-icon {
  filter: brightness(0) invert(1);
}

/* Ajustar padding dos conteúdos dentro de cada item do menu */
.dx-swatch-menu-superior
  .dx-toolbar-button
  .dx-menu
  .dx-menu-item
  .dx-menu-item-content {
  padding-left: 5px;
  padding-right: 5px;
  line-height: normal;
}

/* Ajustar padding dos itens do menu, normal ou em telas menores */
.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 20px 0 0;

  .screen-x-small & {
    padding: 0 10px;
  }
}

/* Ajustar largura e posicionamento do texto dos botões do menu principal */
.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

/* Ajuste de padding e margens dos conteúdos das opções do menu */
.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

/* Ajuste de padding do tema geral para o toolbar e especificamente do botão de troca de usuário */
.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

/* Ajuste largura mínima dos containers dos itens do sub-menu */
.dx-submenu {
  .dx-menu-items-container {
    min-width: 210px;
  }
}

/* Ajuste feitos para o header no modo desktop */
.header-component-desktop
  .dx-menu
  .dx-menu-horizontal
  .dx-menu-item-text::after {
  content: "\f107";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 600;
  font-size: 10px;
  padding-left: 4px;
}

/* Esconde container pop-up das opções no menu para uso dos atalhos */
.header-toolbar
  .dx-menu
  .dx-menu-item
  .dx-menu-item-content
  .dx-menu-item-popout-container {
  display: none !important;
}

/* Ajusta margem e posicionamento do ícone dos itens do menu quando tem ícone */
.header-toolbar
  .dx-menu
  .dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon
  .dx-icon {
  margin-right: 11px;
  align-self: center;
}

/* Ajusta posicionamento do texto dos itens do menu quando tem texto */
.header-toolbar
  .dx-menu
  .dx-menu-item.dx-menu-item-has-text
  .dx-menu-item-text {
  align-self: center;
}

.containerItemMenu {
  display: flex !important;
  flex-direction: row !important ;
  justify-content: space-between !important;
  align-items: center !important;
}

.novo-menu {
  padding: 0em 0.3em;
  margin-left: 0.8em;
  font-weight: 400;
  letter-spacing: 0.1em;
  border: 1px solid #27a306;
  border-radius: 2px;
  background-color: #27a306;
  color: #fff;
}

/* Ajusta cor do ícone do botão de limpar */
.dx-show-clear-button
  .dx-texteditor-container
  .dx-texteditor-buttons-container
  .dx-clear-button-area
  .dx-icon-clear {
  color: rgb(81 142 194);
}

/* Melhorias no header de pesquisa de ajuda */
.dx-swatch-menu-superior
  .dx-toolbar-items-container
  .dx-toolbar-before
  .header-pesquisar-na-ajuda {
  width: calc(150px + (10vw - 150px) * 5);
  background-color: rgba(255, 255, 255, 0.15);
  max-width: 300px;
  margin-left: 8px;
}

.dx-swatch-menu-superior
  .dx-toolbar-items-container
  .dx-toolbar-before
  .header-pesquisar-na-ajuda
  input {
  color: rgba(255, 255, 255, 0.87);
  font-size: 13px;
  font-weight: 500;
}

@media (max-width: 1400px) {
  .dx-swatch-menu-superior
    .dx-toolbar-items-container
    .dx-toolbar-before
    .header-pesquisar-na-ajuda {
    display: none;
  }
}

/* Remover margem dos separadores apenas no menu principal no menu horizontal,
 uma vez que os itens dos menus de contexto e principal são as vezes compartilhados entre si*/
.dx-menu-horizontal .dx-menu-separator {
  margin-inline-end: 0px !important;
}
