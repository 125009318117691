.dx-swatch-menu-superior .btn-estado-assinatura.dx-widget,
.btn-estado-assinatura {
  margin-inline-start: 0.5em;
  font-weight: bold;
}

.mxp-estado-assinatura {
  background-color: antiquewhite;
  box-shadow: inset 0px 0px 6px 1px #de57168f;
}
