/* Mostra sempre o filtro na esquerda. */
.mxp-grid-compacto .dx-column-indicators {
  float: left !important;
}
.mxp-grid-compacto .dx-column-indicators .dx-header-filter {
  float: left;
}

/* Alinhas textos das colunas mais a esquerda. */
.mxp-grid-compacto
  .dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-action
  .dx-datagrid-text-content {
  width: 100%;
  text-align: left;
  margin-left: 3px;
}
