.chipContainer {
  // Define o espaçamento entre os chips
  row-gap: 1em;
  column-gap: 4px;

  // Limita o tamanho da linha para 2 chips.
  max-height: 4em;
}

.chip {
  // Desabilitar a exibição de div padrão como bloco.
  display: inline-block;
  position: relative;

  // Limitar a quantidade de texto que aparece por
  // padrão nos chips.
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Permite que os chips mostrem todo o conteúdo quando
// a célula que contém eles tenha hover.
.chipCell:hover > .chipContainer:not(:empty) {
  $duracao-animacao: 0.5s;

  // Animar o pulo para fora
  animation: $duracao-animacao ease-in 1 normal forwards chipsContainerPopOut;

  // Tamanho final desejado.
  max-width: 600px;
  max-height: 20em;
  transition: width $duracao-animacao, height $duracao-animacao;

  // Aumentar o tamanho dos chips também para não cortar mais o texto
  & > .chip {
    max-width: 600px;
    width: fit-content;
    transition: width 0.2s;
  }
}

// Estilo do container de chips quando ele "pula" para fora da célula.
@mixin container-pulado-para-fora {
  // Fazer com que o container "pule" para fora da célula e fique no
  // topo dos outros elementos.
  display: flex;
  position: absolute;
  margin: -1.2em -0.9em;
  padding: inherit;
  z-index: 1; // Mostrar acima das outras células
  flex-wrap: wrap;
  align-items: center;

  // Definir uma cor de fundo e sombra para indicar que o elemento
  // "pulou" para fora da célula.
  box-shadow: 1px 1px 2px #66666644;
  background-color: azure;
}

// Animar o "pulo para fora" do container
@keyframes chipsContainerPopOut {
  0% {
  }

  // Manter as propriedades de estar "pulado para fora" pelo maior tempo possível.
  // Mudamos isso logo que inicia a animação pois não queremos que haja uma transição
  // entre o display nem position, senão a linha da grid muda de tamanho junto com o
  // container.
  0.01% {
    @include container-pulado-para-fora;
  }

  100% {
    @include container-pulado-para-fora;
  }
}
