@import "./themes/generated/variables.base.scss";

$base-success: #5cb85c;
$base-warning: #ffc107;
$base-error: #d9534f;

.app {
  display: block;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

:root {
  --header-menu-height: 40px;
  --cor-texto: #{$base-text-color};
  --cor-sucesso: #{$base-success};
  --cor-aviso: #{$base-warning};
  --cor-erro: #{$base-error};
  --cor-texto: #{$base-text-color};
  --cor-texto-mais-clara: #{rgba(
      $base-text-color,
      alpha($base-text-color) * 0.7
    )};
}

* {
  box-sizing: border-box;
}
