/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  transition: 0.5s linear;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  transition: background-color 0.5s linear 0.5s;
}

/* Track */
::-webkit-scrollbar-track:hover {
  background-color: rgba(46, 71, 97, 0.1);
  transition: background-color 0.5s linear 0.5s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(46, 71, 97, 0.2);
  transition: background-color 0.5s linear 0.5s;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(46, 71, 97, 0.4);
  transition: background-color 0.5s linear 0.5s;
}
