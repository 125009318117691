.mxp-alerta {
  background: none repeat scroll 0 0 #fef7d4;
  color: var(--cor-texto-mais-clara);
  text-align: center;
  min-height: 34px;
  box-shadow: inset 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  font-weight: 500;
  padding: 1px 20px;
  place-items: center;
  animation: horizontal-shaking 0.5s;
}

.mxp-alerta-texto {
  padding: 6px 0px;
  width: 100%;
}

.dx-swatch-menu-superior .dx-button-content .dx-icon.mxp-alerta-fechar,
.mxp-alerta-fechar {
  color: dimgray;
}

// Este valor estava sendo sobrescrito pelo tema, portanto adicionei
// a classe superior à .mxp-alerta.
.dx-swatch-menu-superior .mxp-alerta .mxp-alerta-texto a {
  color: #4272db;
  cursor: pointer;
  text-decoration: underline;
}

.alerta-icone::before {
  color: var(--cor-texto-mais-clara);
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes alerta-animation {
  from {
    transform: scaleX(0.975);
  }
  to {
    transform: scaleX(1);
  }
  25% {
    transform: scaleX(0.95);
  }
  50% {
    transform: scaleX(1);
  }
  75% {
    transform: scaleX(0.985);
  }
}
