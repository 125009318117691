.ic-material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-variation-settings: "FILL" 0;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.ic-material-disabled {
  opacity: 0.6;
}

.ic-success {
  color: var(--cor-sucesso);
}

.ic-warning {
  color: var(--cor-aviso);
}

.ic-error {
  color: var(--cor-erro);
}

.ic-16 {
  font-size: 16px;
}

.ic-column-icon {
  margin-left: 4px;
  vertical-align: middle;
  line-height: 0;
  font-weight: bold;
}

.ic-text-cell-with-icon {
  display: flex;
  align-items: center;
}

.ic-text-cell-with-icon .ic-text-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ic-2x::before {
  font-size: 1.3em;
}

.ic-manage-search::before {
  content: "manage_search";
}

.ic-data-array::before {
  content: "data_array";
}

.ic-file-upload::before {
  content: "\e9fc";
}

.ic-account-balance::before {
  content: "\e84f";
}

.ic-file-save::before {
  content: "\f17f";
}

.ic-edit::before {
  content: "\e3c9";
}

.ic-delete::before {
  content: "\e872";
}

.ic-vertical::before {
  content: "\e5d4";
}

.ic-refresh::before {
  content: "\e881";
}

.ic-download::before {
  content: "\f090";
}

.ic-arrow-download::before {
  content: "arrow_downward";
}

.ic-restore-page::before {
  content: "restore_page";
}

.ic-pending-actions::before {
  content: "pending_actions";
}

.ic-copy-content::before {
  content: "content_copy";
}

.ic-app-registration::before {
  content: "app_registration";
}

.ic-quick-reference-all::before {
  content: "quick_reference_all";
}

.ic-save::before {
  content: "save";
}

.ic-done-all::before {
  content: "\e877";
}

.ic-block::before {
  content: "\e14b";
}

.ic-cancel::before {
  content: "\e5c9";
}

.ic-star::before {
  content: "\e838";
}

.ic-settings-account-box::before {
  content: "\f835";
}

.ic-info::before {
  content: "\e88e";
}

.ic-done::before {
  content: "\e876";
}

.ic-edit-square::before {
  content: "\f88d";
}

.ic-domain::before {
  content: "domain";
}

.ic-notifications::before {
  content: "notifications";
}

.ic-logout::before {
  content: "logout";
}

.ic-visibility::before {
  content: "visibility";
}

.ic-visibility-off::before {
  content: "visibility_off";
}

.ic-keep {
  content: "\e6aa";
}

.ic-close::before {
  content: "\e5cd";
}

.ic-keep::before {
  content: "keep";
}

.ic-pending {
  content: "\ef64";
}

.ic-pending::before {
  content: "pending";
}

.ic-task-alt::before {
  content: "task_alt";
}

.ic-undo::before {
  content: "undo";
}

.ic-keyboard-arrow-right::before {
  content: "keyboard_arrow_right";
}

.ic-category::before {
  content: "category";
}

.ic-event-avaliable::before {
  content: "\e614";
}

.ic-assignment-return::before {
  content: "\e860";
}

.ic-check::before {
  content: "\e5ca";
}

.ic-check-circle::before {
  content: "\e86c";
}

.ic-remove::before {
  content: "remove";
}
.ic-print {
  content: "\e8ad";
}

.ic-print::before {
  content: "print";
}

.ic-print-add {
  content: "\f7a2";
}

.ic-print-add::before {
  content: "print_add";
}

.ic-format-list-bulleted::before {
  content: "format_list_bulleted";
}

.ic-file-save {
  content: "\f17f";
}

.ic-sync:before {
  content: "\e627";
}

.ic-timer:before {
  content: "\e425";
}

.ic-inventory:before {
  content: "\e179";
}

.ic-alt-route::before {
  content: "\f184";
}

.ic-recycling:before {
  content: "\e760";
}

/* O ícone foi rotacionado por 90 graus pois não existe ele apontado para baixo, 
por isso foi pego o turn-left e rotacionado */
.ic-turn-down {
  transform: rotate(-90deg);
}

.ic-turn-down:before {
  content: "\eba6";
  transform: rotate(15deg);
}

.ic-lab-profile:before {
  content: "\e104";
}

.ic-overview:before {
  content: "\e4a7";
}

.ic-shelves:before {
  content: "\f86e";
}

.ic-list-alt-check:before {
  content: "\f3de";
}

.ic-link::before {
  content: "\e157";
}

.ic-privacy-tip:before {
  content: "\f0dc";
}

.ic-arrow-downward:before {
  content: "\e5db";
}

.ic-arrow-downward:before {
  content: "\e5db";
}

.ic-arrow-downward:before {
  content: "\e5db";
}
