/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84853 */

/* Alinha os itens no centro e ajusta altura */
.grid-edit-number-box .dx-texteditor-container {
  height: 1.6em;
  align-items: center;
}

/* Adiciona uma margem, sombra na box e alinha o texto da última linha à direita */
.grid-edit-number-box,
.dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  .dx-editor-cell
  .grid-edit-number-box.dx-texteditor {
  margin-inline: 5px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  text-align-last: right;
}
