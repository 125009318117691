.showhide.dx-accordion {
  margin-inline: -5px;
  transform: translateZ(0px); // Para evitar blur ao dar zoom
  backface-visibility: hidden; // Para evitar blur ao dar zoom
}

.showhide-item {
  resize: vertical;
  padding-inline: 8px;
  min-height: 100px;
  max-height: 500px;
  height: auto;
  overflow: auto;
}

.showhide .dx-accordion-item-body {
  padding: 4px;
  position: relative;
}

.showhide-item::-webkit-resizer {
  content: "no-icon";
}

.showhide .dx-accordion-item-title {
  min-height: 35px;
}

.showhide .dx-accordion-item-title {
  padding-left: 35px;
  padding-block: 10px;
}

.showhide .dx-accordion-item-opened + .dx-accordion-item,
.showhide .dx-accordion-item:first-child {
  border-top: 1px solid #f0f0f0;
}

.showhide .dx-accordion-item {
  margin-block: 5px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

.showhide .dx-accordion-item-title::before {
  inset-inline-start: 11px;
  inset-inline-end: 0;
  font-weight: 800;
}

.showhide
  .dx-accordion-item.dx-state-focused.dx-accordion-item-opened:not(
    .dx-state-hover
  )
  .dx-accordion-item-title {
  background-color: transparent;
}
