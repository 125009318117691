/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-83073 */

/* Reduz o tamanho do filter panel da grid */
/* Posiciona o filtro acima da grid. */
.dx-datagrid > .dx-datagrid-filter-panel {
  order: -1 !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}

/* Deixa o filter panel mais compacto. */
.mxp-grid-compacto .dx-datagrid > .dx-datagrid-filter-panel {
  padding: 6px 11px;
}

/* Ajusta cor ao dar hover no elemento e ajusta as bordas. */
.dx-datagrid-filter-panel:hover {
  background-color: whitesmoke;
  border-radius: 4px;
}

/* Muda icone de filtragem do filter panel. */
.dx-datagrid-filter-panel .dx-datagrid-filter-panel-left .dx-icon-filter,
.dx-datagrid > .dx-datagrid-headers .dx-column-indicators > .dx-header-filter {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

/* Ajusta o valor descritivo do atributo content do ícone do filter panel e do header. */
.dx-datagrid-filter-panel
  .dx-datagrid-filter-panel-left
  .dx-icon-filter::before,
.dx-datagrid
  > .dx-datagrid-headers
  .dx-column-indicators
  > .dx-header-filter::before {
  content: "filter_alt";
}

.dx-datagrid-filter-panel
  .dx-datagrid-filter-panel-left
  .dx-icon-filter:not(.dx-header-filter-empty)::before,
.dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-action
  .dx-column-indicators
  .dx-header-filter:not(.dx-header-filter-empty)::before,
.dx-datagrid
  .dx-datagrid-headers
  .dx-datagrid-drag-action
  .dx-column-indicators
  .dx-header-filter:not(.dx-header-filter-empty)::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: small;
  content: "\f0b0";
  vertical-align: bottom;
}
