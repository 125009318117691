/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84893 */

/* Permite que o rodape dos editforms se comporte corretamente junto com o botao de editar links das tooltips*/
.dx-toolbar-items-container {
  display: flex;
}
.dx-toolbar-after,
.dx-toolbar-before {
  position: inherit;
  display: flex;
  flex-wrap: wrap;
}
.dx-toolbar-center:empty {
  justify-self: center;
  display: flex;
}
.dx-toolbar-after {
  justify-self: right;
}
.dx-toolbar-before {
  justify-self: left;
}

/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84913 */

/* Estilos gerais*/
.centralizar-itens {
  margin-top: 22px;
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: left;
}

.float-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

/* Para evitar blur ao dar zoom em um popup */
.dx-popup-draggable.dx-overlay-content {
  overflow: unset;
  backface-visibility: hidden;
}

/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84913 */

/* Troca a cor dos toast do tipo "info" de preto para azul */
.dx-toast-info {
  background-color: rgb(0, 124, 182) !important;
}

.dx-toast-warning {
  background-color: rgb(224, 202, 0) !important;
}
