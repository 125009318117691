.dx-dropdownbutton
  .dx-button-success.dx-widget.dx-button-mode-contained.dx-button-has-text
  .dx-button-content:hover,
.dx-dropdownbutton
  .dx-button-success.dx-widget.dx-button-mode-contained
  .dx-button-content:hover {
  background-color: #47a247;
}

.dx-dropdownbutton
  .dx-button-success.dx-widget.dx-button-mode-contained
  .dx-button-content {
  background-color: #5cb85c;
  color: #fff;
}

.dx-dropdownbutton .dx-button-success .dx-icon.dx-icon-spindown {
  color: #fff !important;
}

.dx-dropdownbutton
  .dx-button-success.dx-widget.dx-button-mode-contained
  .dx-button-content {
  background-color: #5cb85c;
  color: #fff;
}

.dx-dropdownbutton .dx-button-success.dx-widget.dx-button-mode-contained {
  border-inline-end-color: #fff;
}
