/* Essas customizações estão documentadas em https://app.clickup.com/9007115994/v/dc/8cdvbpu-19283/8cdvbpu-84933 */

@import "../../../themes/generated/variables.base.scss";
$side-panel-min-width: 60px;

.menu-superior-form {
  margin-bottom: 2px;
}

/* Ajustar padding do conteúdo dos itens do menu  */
.menu-superior-form
  .dx-menu-horizontal
  .dx-menu-items-container
  .dx-menu-item-wrapper
  .dx-item.dx-menu-item
  .dx-item-content {
  padding: 2px;
}

.separador-menu-superior {
  font-weight: 300;
  opacity: 0.3;
  margin-bottom: 25px;
  margin-top: 0;
}
